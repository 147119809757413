import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="./videos/video.mp4" autoPlay loop muted />
      <h1>MacBook and PC Repair</h1>
      <p>IT Services and Web Development</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          onClick={(event) =>
            (window.location.href = "https://forms.gle/QshaUBdKhdrCuwNt7")
          }
        >
          CONTACT US
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={(event) =>
            (window.location.href =
              "https://calendly.com/dobettertech/remotesupport")
          }
        >
          SCHEDULE SERVICE
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
