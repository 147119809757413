import React from "react";
import "../../App.css";
import Slideshow from "../Slideshow";
import Footer from "../Footer";

export default function Work() {
  return (
    <>
      <Slideshow />
      <Footer />
    </>
  );
}
