import React from "react";
import CardItem from "./CardItem";

import "./About.css";

const About = () => {
  return (
    <div className="about__container">
      <h2 className="about__header">About</h2>

      <div className="callout__color">
        <div>
          <div className="color__strip"></div>
          We provide amazing IT support, web design and development services,
          mobile app development and networking upgrades and repairs, custom
          application development, and graphics design. We work for each other,
          and we work for you.
        </div>
      </div>

      <div className="about__text">
        <strong>Do Better Tech</strong> is a technology services company and
        worker cooperative. We provide remote IT support, web design and
        development services, networking upgrades and repairs, custom mobile and
        web application development, and graphic design. But beyond our
        technical acumen, as a worker cooperative, we put our ethics and our
        optimism at the heart of our work. <br />
        <br />
        We are intent on providing high-quality technology services, to small
        businesses and union locals, in a way that benefits our customers and
        the member-owners of our cooperative. We are tired of the status quo and
        we've decided, together we can do better.
      </div>
    </div>
  );
};

export default About;
