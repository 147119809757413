import React, { useState } from "react";
import "./Slideshow.css";

const SLIDESHOW_IMAGES = [
  {
    id: "0",
    title: "DAIS Dice Roller",
    src: "./images/dais_project.jpeg",
  },
  {
    id: "1",
    title: "LMCC Website",
    src: "./images/lmcc_project.jpeg",
  },
  {
    id: "2",
    title: "Ironworker Logo and Support",
    src: "./images/iw_project.jpeg",
  },
  {
    id: "3",
    title: "Braul Supply Website",
    src: "./images/braul_project.jpeg",
  },
  {
    id: "4",
    title: "NECA Website",
    src: "./images/neca_project.jpeg",
  },
];

const Slideshow = () => {
  const [imageChosen, setImageChosen] = useState(0);

  const setImageForwardHandler = () => {
    if (imageChosen != SLIDESHOW_IMAGES.length-1)   {
        console.log(imageChosen + 1);
        setImageChosen(imageChosen + 1);
    } 
  };

  const setImageBackwardHandler = () => {
    if (imageChosen != 0)   {
        console.log(imageChosen - 1);
        setImageChosen(imageChosen - 1);
    }
  };

  let currentImage = SLIDESHOW_IMAGES[imageChosen].src;

  return (
    <div className="slideshow__container">
      <div>
        <button className="slider__left" onClick={setImageBackwardHandler}>&#10094;</button>
        <img className="work__image" src={currentImage.toString()} />
        <button className="slider__right" onClick={setImageForwardHandler}>&#10095;</button>
      </div>
    </div>
  );
};

export default Slideshow;
